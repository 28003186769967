<template>
  <b-card title="Instructor">
    <b-tabs>
    <b-tab active  @click="$router.push('/create-instructor')">
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>New Instructor</span>
        </template>
<AddInstructor @add-instructor="getData"></AddInstructor>
      </b-tab>
      
      <b-tab  @click="$router.push('/instructors-list')"> 
        <template #title>
          <feather-icon icon="HomeIcon" />
          <span>Instructor List</span>
        </template>
        <router-view></router-view>
<!-- <InstructorList ref="allData"></InstructorList> -->
      </b-tab>
      
     
    </b-tabs>

  </b-card>
</template>

<script>

import { BTabs, BTab, BCardText,BCard } from 'bootstrap-vue'



import InstructorList from './InstructorList.vue';
import AddInstructor from './AddInstructor.vue';
import { ref } from "@vue/composition-api";

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
   
    InstructorList,
    AddInstructor
},
setup() {
    const allData=ref()

  
    const getData=()=>{
      console.log("Eee")
    
      allData.value.refetchData()

           

    }
   return{
    getData,
  
    allData,
   

   }
   
   }
}
</script>
